






import { Component, Vue } from 'vue-property-decorator'
import { redirectPageTop } from '@/utils/teacher/indext'

@Component
export default class Error500 extends Vue {
  private get topPage(): string {
    const role = Vue.prototype.$cookies.get('authGdls').webRole
    let path: string
    switch (role) {
      case 'admin':
        path = '/admin/branch'
        break
      case 'academy':
        path = '/academy/branch'
        break
      case 'branch':
        path = '/teacher/dashboard'
        break
      case 'teacher':
        path = '/teacher/dashboard'
        break
      case 'parent':
        path = '/student/dashboard'
        break
      case 'student':
        path = redirectPageTop(Vue)
        break
      default:
        path = '/error/error500'
        break
    }
    return path
  }
}
