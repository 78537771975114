import _Vue from 'vue'

const rand = () => {
  return Math.random().toString(36).substring(2)
}

export const genToken = () => {
  return rand() + rand()
}

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const redirectPageTop = (Vue: typeof _Vue) => {
  const isV3 = Vue.prototype.$gdlsCookiesV3?.isV3()
  const isQuiz = Vue.prototype.$quiz.isQuiz();
  if (isQuiz) {
    const book = localStorage.getItem('book')
    if (!book) {
      return '/quiz/error'
    } else {
      return `/quiz?book=${JSON.parse(book).code}`
    }
  }
  return isV3 ? '/student/v3/dashboard' : '/student/dashboard'
}
